

























import { Component, Prop, Watch } from 'vue-property-decorator';
import { BaseComponent } from '@/mixins/base-component';
import CommonService from '@/service/common';

@Component
export default class DeviceStatusComponent extends BaseComponent {
    @Prop()
    deviceId: string;
    @Prop()
    deviceAttributeKey: string;

    attributeList: Array<{name:string, value:string}> = [];
    methodList: Array<any> = [];

    @Watch('deviceId', { immediate: true, deep: true })
    deviceIdChange(newVal: string) {
        if (newVal) {
            CommonService.getDeviceStatus(newVal).then(res => {
                if (this.deviceAttributeKey) {
                    this.attributeList = res.filter(item => { return item.identifier === this.deviceAttributeKey; });
                } else {
                    this.attributeList = res;
                }
            });
        }
    }
}
