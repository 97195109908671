var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-collapse",
    { attrs: { "default-active-key": ["1", "2"] } },
    [
      _c(
        "a-collapse-panel",
        { key: "1", attrs: { header: "运行状态" } },
        [
          _c("a-list", {
            attrs: { size: "small", "data-source": _vm.attributeList },
            scopedSlots: _vm._u([
              {
                key: "renderItem",
                fn: function(item) {
                  return _c(
                    "a-list-item",
                    {},
                    [
                      _c(
                        "a-row",
                        [
                          _c("a-col", { attrs: { span: 12 } }, [
                            _vm._v(_vm._s(item.name))
                          ]),
                          _c("a-col", { attrs: { span: 12 } }, [
                            _vm._v(_vm._s(item.value))
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }