
















import { Component } from 'vue-property-decorator';
import { BaseComponent } from '@/mixins/base-component';
import DeviceService from '@/service/device';
import DeviceStatusComponent from './device-status.vue';

@Component({
    components: {
        'device-status-component': DeviceStatusComponent
    }
})
export default class DeviceStatusDialog extends BaseComponent {
    dialogTitle: string = '详情';
    dialogVisible: boolean = false;
    deviceId: string = null;
    deviceAttributeKey: string = null;

    dialogOpen(entityId: string, deviceAttributeKey?: string) {
        this.dialogVisible = true;
        this.deviceId = entityId;
        this.deviceAttributeKey = deviceAttributeKey;
        DeviceService.retrieve(entityId).then(res => {
            let deviceInfo = _.get(res, 'deviceModel.displayName');
            if (this.deviceAttributeKey) {
                deviceInfo += ` | ${this.deviceAttributeKey}`;
            }
            this.dialogTitle = deviceInfo;
        });
    }

    dialogClose() {
        this.dialogVisible = false;
    }
}
